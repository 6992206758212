import { useState, useEffect, useRef } from 'react';
import { collection, query, orderBy, limit, getDocs, addDoc } from 'firebase/firestore';
import { db } from '../../firebase'; 
import '../css/WebGame.css';

export const Webgame = () => {
    const canvasRef = useRef(null);
    const snakeHeadImg = useRef(new Image());
    const foodImg = useRef(new Image());
    const [imagesLoaded, setImagesLoaded] = useState(false);
    const [snake, setSnake] = useState([
        { x: 10, y: 10 },
        { x: 10, y: 9 },
        { x: 10, y: 8 },
    ]);
    const [food, setFood] = useState({ x: 15, y: 15 });
    const [direction, setDirection] = useState({ x: 0, y: 1 });
    const [nextDirection, setNextDirection] = useState({ x: 0, y: 1 });
    const [gameOver, setGameOver] = useState(false);
    const [paused, setPaused] = useState(false);
    const [score, setScore] = useState(0);
    const [canvasSize, setCanvasSize] = useState({ width: 400, height: 400 });
    const [isDragging, setIsDragging] = useState(false);
    const [lastTouchMove, setLastTouchMove] = useState(0);
    const [highScores, setHighScores] = useState([]);
    const [nickname, setNickname] = useState('');
    const [showNicknameInput, setShowNicknameInput] = useState(false);

    useEffect(() => {
        snakeHeadImg.current.src = "/images/snake-head.png";
        foodImg.current.src = "/images/food.png";
        const handleImageLoad = () => {
            if (snakeHeadImg.current.complete && foodImg.current.complete) {
                setImagesLoaded(true);
            }
        };

        snakeHeadImg.current.onload = handleImageLoad;
        foodImg.current.onload = handleImageLoad;

        const updateCanvasSize = () => {
            const size = Math.min(window.innerWidth, window.innerHeight) - 40;
            setCanvasSize({ width: size, height: size });
        };
        updateCanvasSize();
        window.addEventListener('resize', updateCanvasSize);
        return () => window.removeEventListener('resize', updateCanvasSize);
    }, []);

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        const interval = setInterval(() => {
            if (!gameOver && !paused) {
                moveSnake();
                drawGame(context);
            }
        }, 100);
        return () => clearInterval(interval);
    }, [snake, food, direction, gameOver, paused, imagesLoaded, canvasSize]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            // 기본 동작 방지
            if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(event.key)) {
                event.preventDefault();
            }

            switch (event.key) {
                case 'ArrowUp':
                    if (direction.y !== 1) setNextDirection({ x: 0, y: -1 });
                    break;
                case 'ArrowDown':
                    if (direction.y !== -1) setNextDirection({ x: 0, y: 1 });
                    break;
                case 'ArrowLeft':
                    if (direction.x !== 1) setNextDirection({ x: -1, y: 0 });
                    break;
                case 'ArrowRight':
                    if (direction.x !== -1) setNextDirection({ x: 1, y: 0 });
                    break;
                case ' ':
                    setPaused(!paused);
                    break;
                default:
                    break;
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [direction, paused]);

    useEffect(() => {
        // Fetch high scores from Firestore
        const fetchHighScores = async () => {
            const highScoresCollection = collection(db, 'highScores');
            const highScoresQuery = query(highScoresCollection, orderBy('score', 'desc'), limit(10));
            const snapshot = await getDocs(highScoresQuery);
            const scores = snapshot.docs.map(doc => doc.data());
            setHighScores(scores);
        };

        fetchHighScores();
    }, []);

    const moveSnake = () => {
        const newSnake = [...snake];
        const head = { x: newSnake[0].x + nextDirection.x, y: newSnake[0].y + nextDirection.y };

        // 충돌 검사
        if (
            head.x < 0 || head.x >= 20 || head.y < 0 || head.y >= 20 ||
            newSnake.some(segment => segment.x === head.x && segment.y === head.y)
        ) {
            setGameOver(true);
            if (score > 0 && (highScores.length < 10 || score > highScores[9].score)) {
                setShowNicknameInput(true);
            }
            return;
        }

        newSnake.unshift(head);

        if (head.x === food.x && head.y === food.y) {
            setFood({ x: Math.floor(Math.random() * 20), y: Math.floor(Math.random() * 20) });
            setScore(score + 1);
        } else {
            newSnake.pop();
        }

        setSnake(newSnake);
        setDirection(nextDirection);
    };

    const drawGame = (context) => {
        context.clearRect(0, 0, context.canvas.width, context.canvas.height);

        // Draw snake
        snake.forEach((segment, index) => {
            if (index === 0 && imagesLoaded) {
                context.drawImage(snakeHeadImg.current, segment.x * (canvasSize.width / 20), segment.y * (canvasSize.height / 20), canvasSize.width / 20, canvasSize.height / 20);
            } else {
                context.fillStyle = 'green';
                context.fillRect(segment.x * (canvasSize.width / 20), segment.y * (canvasSize.height / 20), canvasSize.width / 20, canvasSize.height / 20);
            }
        });

        // Draw food
        if (imagesLoaded) {
            context.drawImage(foodImg.current, food.x * (canvasSize.width / 20), food.y * (canvasSize.height / 20), canvasSize.width / 20, canvasSize.height / 20);
        } else {
            context.fillStyle = 'red';
            context.fillRect(food.x * (canvasSize.width / 20), food.y * (canvasSize.height / 20), canvasSize.width / 20, canvasSize.height / 20);
        }

        if (gameOver) {
            context.fillStyle = 'black';
            context.font = '48px serif';
            context.fillText('Game Over', canvasSize.width / 6, canvasSize.height / 2);
        }
    };

    const handleTouchStart = (event) => {
        event.preventDefault(); // 터치 시작 시 기본 동작 방지
        setIsDragging(true);
    };

    const handleTouchMove = (event) => {
        event.preventDefault(); // 터치 이동 시 기본 동작 방지
        if (isDragging) {
            const currentTime = Date.now();
            if (currentTime - lastTouchMove > 50) { // Debounce touch move events
                const touch = event.touches[0];
                handleMovement(touch.clientX, touch.clientY);
                setLastTouchMove(currentTime);
            }
        }
    };

    const handleTouchEnd = (event) => {
        event.preventDefault(); // 터치 종료 시 기본 동작 방지
        setIsDragging(false);
    };

    const handleMouseDown = (event) => {
        event.preventDefault(); // 마우스 다운 시 기본 동작 방지
        setIsDragging(true);
        handleMovement(event.clientX, event.clientY);
    };

    const handleMouseMove = (event) => {
        event.preventDefault(); // 마우스 이동 시 기본 동작 방지
        if (isDragging) {
            handleMovement(event.clientX, event.clientY);
        }
    };

    const handleMouseUp = (event) => {
        event.preventDefault(); // 마우스 업 시 기본 동작 방지
        setIsDragging(false);
    };

    const handleMovement = (clientX, clientY) => {
        const canvas = canvasRef.current.getBoundingClientRect();
        const centerX = canvas.left + canvas.width / 2;
        const centerY = canvas.top + canvas.height / 2;
        const deltaX = clientX - centerX;
        const deltaY = clientY - centerY;

        if (Math.abs(deltaX) > Math.abs(deltaY)) {
            if (deltaX > 0 && direction.x !== -1) {
                setNextDirection({ x: 1, y: 0 });
            } else if (deltaX < 0 && direction.x !== 1) {
                setNextDirection({ x: -1, y: 0 });
            }
        } else {
            if (deltaY > 0 && direction.y !== -1) {
                setNextDirection({ x: 0, y: 1 });
            } else if (deltaY < 0 && direction.y !== 1) {
                setNextDirection({ x: 0, y: -1 });
            }
        }
    };

    const handleNicknameSubmit = async () => {
        const newHighScores = [...highScores, { nickname, score }];
        newHighScores.sort((a, b) => b.score - a.score);
        if (newHighScores.length > 10) {
            newHighScores.pop();
        }
        setHighScores(newHighScores);
        setShowNicknameInput(false);

        // Save high score to Firestore
        await addDoc(collection(db, 'highScores'), { nickname, score });
    };

    const restartGame = () => {
        setSnake([
            { x: 10, y: 10 },
            { x: 10, y: 9 },
            { x: 10, y: 8 },
        ]);
        setFood({ x: 15, y: 15 });
        setDirection({ x: 0, y: 1 });
        setNextDirection({ x: 0, y: 1 });
        setGameOver(false);
        setScore(0);
        setShowNicknameInput(false);
    };

    return (
        <div className="WebGame-Wrap">
            <div className="info">
                <h1 className='Title'>웹 게임</h1>
                <p>Score: {score}</p>
                
            </div>
            <canvas
                ref={canvasRef}
                width={canvasSize.width}
                height={canvasSize.height}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
            />
            <div>
                {gameOver && !showNicknameInput && <button onClick={restartGame}>Restart</button>}
                {gameOver && showNicknameInput && (
                    <div>
                        <input
                            type="text"
                            value={nickname}
                            onChange={(e) => setNickname(e.target.value)}
                            placeholder="순위를 등록하세요!"
                            maxLength={5}
                        />
                        <button onClick={handleNicknameSubmit}>Submit</button>
                    </div>
                )}
                <button onClick={() => setPaused(!paused)}>{paused ? 'Resume' : 'Pause'}</button>
            </div>

            <div className='highscores'>
                    <h2>최고점수 Top 10</h2>
                    <ol>
                        {highScores.map((entry, index) => (
                            <li key={index}>{entry.nickname}: {entry.score}점</li>
                        ))}
                    </ol>
                </div>
        </div>
        
    );
};
