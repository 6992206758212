import React, { useState, useEffect, useRef, useCallback } from 'react';
import { getKoreanType } from '../data/PokemonName.jsx';
import '../css/Pokemon.css';

export const Pokemon = () => {
    const [basicPokemonInfo, setBasicPokemonInfo] = useState([]);
    const [allPokemonDetails, setAllPokemonDetails] = useState({});
    const [loading, setLoading] = useState(true);
    const [selectedPokemon, setSelectedPokemon] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [evolutionDetails, setEvolutionDetails] = useState([]);
    const [offset, setOffset] = useState(0);

    const limit = 50; // 한 번에 가져올 포켓몬 수
    const loadMoreTriggerRef = useRef();

    const fetchBasicPokemonInfo = useCallback(async () => {
        setLoading(true);
        try {
            const response = await fetch(`https://pokeapi.co/api/v2/pokemon-species?limit=${limit}&offset=${offset}`);
            const data = await response.json();

            const basicInfo = await Promise.all(data.results.map(async (pokemon, index) => {
                const resSpecies = await fetch(pokemon.url);
                const speciesData = await resSpecies.json();
                const koreanName = speciesData.names.find(name => name.language.name === 'ko')?.name || pokemon.name;

                return {
                    id: offset + index + 1, // API의 ID를 사용
                    name: koreanName,
                    url: `https://pokeapi.co/api/v2/pokemon/${offset + index + 1}/`,
                };
            }));

            setBasicPokemonInfo(prevInfo => [...prevInfo, ...basicInfo]);
        } catch (error) {
            console.error('Error 포켓몬정보:', error);
        }
        setLoading(false);
    }, [offset]);

    useEffect(() => {
        fetchBasicPokemonInfo();
    }, [fetchBasicPokemonInfo]);

    const fetchPokemonDetails = async (pokemon) => {
        if (allPokemonDetails[pokemon.id]) {
            return allPokemonDetails[pokemon.id];
        }

        try {
            const resPokemonDetail = await fetch(pokemon.url);
            const pokemonDetail = await resPokemonDetail.json();
            const resSpecies = await fetch(pokemonDetail.species.url);
            const speciesData = await resSpecies.json();
            const koreanName = speciesData.names.find(name => name.language.name === 'ko')?.name || pokemon.name;
            const flavorTextEntry = speciesData.flavor_text_entries.find(entry => entry.language.name === 'ko')?.flavor_text || speciesData.flavor_text_entries.find(entry => entry.language.name === 'en')?.flavor_text || "";

            const pokemonDetailWithMoreInfo = {
                ...pokemonDetail,
                koreanName,
                flavorText: flavorTextEntry,
            };

            setAllPokemonDetails(prevDetails => ({
                ...prevDetails,
                [pokemon.id]: pokemonDetailWithMoreInfo,
            }));

            return pokemonDetailWithMoreInfo;
        } catch (error) {
            console.error('Error 포켓몬 상세정보:', error);
        }
    };

    const fetchEvolutionChain = async (speciesUrl) => {
        try {
            const speciesRes = await fetch(speciesUrl);
            const speciesData = await speciesRes.json();
            const evoChainRes = await fetch(speciesData.evolution_chain.url);
            const evoChainData = await evoChainRes.json();

            const processChain = async (node) => {
                const results = [];

                const traverse = async (currentNode, path = []) => {
                    const speciesRes = await fetch(currentNode.species.url);
                    const speciesData = await speciesRes.json();

                    const pokemonId = speciesData.id;
                    const imageUrl = `https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/${pokemonId}.png`;

                    const koreanNameEntry = speciesData.names.find(name => name.language.name === 'ko');
                    const koreanName = koreanNameEntry ? koreanNameEntry.name : currentNode.species.name;

                    const newPath = [...path, { name: koreanName, image: imageUrl }];

                    if (currentNode.evolves_to.length > 0) {
                        await Promise.all(currentNode.evolves_to.map(async (nextNode) => {
                            await traverse(nextNode, newPath);
                        }));
                    } else {
                        results.push(newPath);
                    }
                };

                await traverse(node);
                return results;
            };

            const paths = await processChain(evoChainData.chain);
            setEvolutionDetails(paths);
        } catch (error) {
            console.error('Error 포켓몬진화', error);
        }
    };

    const renderEvolutionDetails = () => {
        return evolutionDetails.map((path, index) => (
            <div key={index} className="EvolutionPath">
                {path.map((stage, stageIndex) => (
                    <React.Fragment key={stageIndex}>
                        <div className="EvolutionStep">
                            <img src={stage.image} alt={stage.name} />
                            <h2 className='Poke_Name'>{stage.name}</h2>
                        </div>
                        {stageIndex < path.length - 1 && <span className="EvolutionArrow">→</span>}
                    </React.Fragment>
                ))}
            </div>
        ));
    };

    const handlePokemonClick = async (pokemon) => {
        const details = await fetchPokemonDetails(pokemon);
        setSelectedPokemon(details);
        setEvolutionDetails([]);
        setShowModal(true);
        await fetchEvolutionChain(details.species.url);
    };

    const filteredPokemon = basicPokemonInfo.filter(pokemon => {
        const idMatches = pokemon.id.toString().padStart(3, '0').includes(searchTerm);
        const nameMatches = pokemon.name.toLowerCase().includes(searchTerm.toLowerCase());
        return idMatches || nameMatches;
    });

    const closeModal = (e) => {
        if (e.target.id === "modalBackdrop") {
            setShowModal(false);
        }
        if (e.currentTarget === e.target) {
            setShowModal(false);
        }
    };

    useEffect(() => {
        if (loading) return;

        const observerCallback = (entries) => {
            if (entries[0].isIntersecting) {
                setOffset(prevOffset => prevOffset + limit);
            }
        };

        const observerInstance = new IntersectionObserver(observerCallback);
        const triggerElement = loadMoreTriggerRef.current;

        if (triggerElement) {
            observerInstance.observe(triggerElement);
        }

        return () => {
            if (triggerElement) {
                observerInstance.unobserve(triggerElement);
            }
        };
    }, [loading]);

    return (
        <div className='noScroll'>
            <div className="Poke_Container">
                <h1 className='Title'>포켓몬 도감</h1>
                <input
                    className='Poke_Search'
                    type="text"
                    placeholder="이름, 타입, 도감 번호로 검색하세요"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                {loading && basicPokemonInfo.length === 0 ? (
                    <span className='Loading'></span>
                ) : (
                    <>
                        <ul className="Poke_List_Wrap">
                            {filteredPokemon.map(pokemon => (
                                <li className='Poke_Wrap' key={pokemon.id} onClick={() => handlePokemonClick(pokemon)}>
                                    <p className='Poke_Num'>No. {pokemon.id.toString().padStart(3, '0')}</p>
                                    <div className='Img_Wrap'><img src={`https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/${pokemon.id}.png`} alt={pokemon.name} /></div>
                                    <h2 className='Poke_Name'>{pokemon.name}</h2>
                                </li>
                            ))}
                        </ul>
                        <div id="loadMoreTrigger" ref={loadMoreTriggerRef} className="LoadMoreTrigger"></div>
                        {showModal && selectedPokemon && (
                            <div className="ModalBackdrop" onClick={closeModal}>
                                <div className="Modal" onClick={e => e.stopPropagation()}>
                                    <button className='Modal_Close' onClick={() => setShowModal(false)}>X</button>
                                    <p>No. {selectedPokemon.id.toString().padStart(3, '0')}</p>
                                    <div className='Img_Wrap'><img src={selectedPokemon.sprites.front_default} alt={selectedPokemon.koreanName} /></div>
                                    <h2 className='Poke_Name'>{selectedPokemon.koreanName}</h2>
                                    <p>{selectedPokemon.flavorText}</p>
                                    <p>{selectedPokemon.genus}</p>
                                    <div className='Cnt'>
                                        <div className="Poke_Type_Wrap">
                                            {selectedPokemon.types.map((type, index) => (
                                                <span key={index} className={`Poke_Type ${type.type.name}`}>{getKoreanType(type.type.name)}</span>
                                            ))}
                                        </div>
                                        <div className="Poke_WH_Wrap">
                                            <span className='Poke_WH'>높이 :
                                            {selectedPokemon.height / 10} m</span>
                                        </div>
                                        <div className="Poke_WH_Wrap">
                                            <span className='Poke_WH'>무게 :
                                            {selectedPokemon.weight / 10} kg</span>
                                        </div>
                                    </div>
                                    <div className="PokemonSprites">
                                        <span className='Poke_Shape'>기본 모습<img src={selectedPokemon.sprites.front_default} alt="기본 모습" /></span>
                                        <span className='Poke_Shape'>이로치 모습<img src={selectedPokemon.sprites.front_shiny} alt="이로치 모습" /></span>
                                    </div>
                                    {/* 진화 과정 표시 */}
                                    <h2 className='Poke_Name'>진화 과정</h2>
                                    <div className="EvolutionDetails">
                                        {renderEvolutionDetails()}
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};
